import { apply, css, defineConfig } from "@twind/core";
import presetAutoprefix from "@twind/preset-autoprefix";
import presetLineClamp from "@twind/preset-line-clamp";
import presetTailwind from "@twind/preset-tailwind";
import { type DefaultTheme } from "tailwindcss/types/generated/default-theme";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const defaultTheme = require("tailwindcss/defaultTheme") as DefaultTheme;

export default defineConfig({
    presets: [presetAutoprefix(), presetTailwind(), presetLineClamp()],
    hash: false,
    theme: {
        container: {
            screens: {
                sm: "640px",
                md: "768px",
                lg: "1024px",
                xl: "1200px",
                // "2xl": "1400px",
            },
            padding: {
                DEFAULT: "1rem",
                sm: "0rem",
                md: "1rem",
                lg: "1rem",
                xl: ".5rem",
            },
        },
        extend: {
            colors: {
                primary: "#016560",
                secondary: "#434D68",
                spring: "#016560",
                evenlode: "#6A90DC",
                zennor: "#D37E09",
                chelverton: "#393F75",
                fulcrum: "#2C2C36",
                tata: "#0876B9",
                offWhite: "#F1F1F1",
                offWhiteDark: "#c1c1c1",
                greenHover: "#009774",
                purple: "#4F4F7D",
                corePurple: "#434D68",
                coreGray: "#454D66",
                darkBlue: "#127AD2",
                lightBlue: "#DEEDFF",
                springGrey: "#424d69",
                notify: "rgba(69, 77, 102, 0.20)",
            },
            fontFamily: {
                sans: ["Source Sans 3"],
            },
            maxWidth: {
                "6xl": "1200px",
                "7xl": "1357px",
            },
            screens: {
                "7xl": "30000px",
            },
            fontSize: {
                "2xs": ["10px", "12px"],
                "3xl": "2rem",
                "4xl": "2.625rem",
            },
        },
        backgroundImage: {
            gapRight: `linear-gradient(to right, #F1F1F1 calc(100% - ${defaultTheme.spacing[1]}), white calc(100% - ${defaultTheme.spacing[1]}))`,
            gapLeft: `linear-gradient(to left, #F1F1F1 calc(100% - ${defaultTheme.spacing[1]}), white calc(100% - ${defaultTheme.spacing[1]}))`,
            gapX: `linear-gradient(to right, white ${defaultTheme.spacing[2]}, #F1F1F1 ${defaultTheme.spacing[2]}, #F1F1F1 calc(100% - ${defaultTheme.spacing[1]}), white calc(100% - ${defaultTheme.spacing[1]}))`,
        },
    },
    preflight: css`
        #root {
            @apply max-w-screen;
        }
        p {
            @apply text-sm md:text-base;
        }
        h2 {
            @apply text-secondary mb-4 text-[2rem] font-light leading-none tracking-tight sm:mb-8 sm:text-4xl sm:font-extralight;
        }
        h3 {
            @apply text-secondary mb-4 text-2xl leading-none tracking-tight sm:text-[2rem] sm:font-extralight;
        }
        h4 {
            @apply text-secondary mb-2 text-xl tracking-tight sm:mb-2 sm:text-2xl sm:leading-none;
        }
        h5 {
            @apply text-secondary mb-1 text-base font-semibold leading-none tracking-tight sm:mb-1 sm:text-base;
        }
        .menu-link {
            @apply after:bg-greenHover relative cursor-pointer after:absolute after:bottom-[-5px] after:left-0 after:h-0.5 after:w-0 after:transition-all after:duration-300 hover:after:w-full;
        }
        .menu-link.active {
            @apply text-corePurple font-semibold;
        }
        .button {
            @apply bg-secondary group flex items-center justify-between gap-2 rounded-xl px-7 py-4 text-lg font-medium leading-6 text-white disabled:opacity-50 group-hover:translate-y-0 h-[54px];
        }
        .button-arrow {
            transform transition-transform duration-1000 translate-x-1 ease-in-out group-hover:translate-x-3;
        }
        .text-part p {
            @apply pb-2;
        }
    `,
});
